import { Anchor, Card, Container, Group, SimpleGrid, Text, createStyles, rem } from '@mantine/core'
import { IconApi, IconApps, IconMail } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  cardTitle: {
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}))

export function KeyFeatures() {
  const { classes, theme } = useStyles()

  return (
    <Container size="lg" py="xl">
      <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
        <Card shadow="md" radius="md" padding="xl">
          <IconApps size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
          <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
            Join now
          </Text>
          <Text fz="sm" c="dimmed" mt="sm">
            The sooner you start using our app, the sooner you can enjoy the benefits of AI
            conversation.
          </Text>
        </Card>

        <Card shadow="md" radius="md" padding="xl">
          <IconApi size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
          <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
            API / Whitelabel (request by email)
          </Text>
          <Text fz="sm" c="dimmed" mt="sm">
            Customize the interface with your own branding and messaging, creating a seamless and
            unique user experience for your customers. Stand out from the competition and elevate
            your brand with our whitelabel solution. Get started today and unleash the full
            potential of your business.
          </Text>
        </Card>

        <Card shadow="md" radius="md" padding="xl">
          <IconMail size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
          <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
            Need help?
          </Text>
          <Text fz="sm" c="dimmed" mt="sm">
            stefan@nolimitai.io
          </Text>
          <Group mt="xl">
            <Anchor href="/tos" target="_blank">
              Terms & Services / Privacy policy
            </Anchor>
          </Group>
        </Card>
      </SimpleGrid>
    </Container>
  )
}
