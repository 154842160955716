import {
  Container,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  createStyles,
  rem,
} from '@mantine/core'
import {
  IconBookmarks,
  IconMessages,
  IconMicrophone,
  IconMoon,
  IconPhotoEdit,
  IconTextSize,
} from '@tabler/icons-react'

const data = [
  {
    image: IconMessages,
    title: 'Chat History',
    description:
      'Say goodbye to the frustration of losing valuable information and hello to the convenience of having all your chats organized and easily accessible.',
  },
  {
    image: IconPhotoEdit,
    title: 'Custom characters',
    description:
      'Need a personalized assistant or just an AI buddy? Create your own AI characters.',
  },
  {
    image: IconBookmarks,
    title: 'Message Bookmarks',
    description: `Whether it's a crucial business conversation or a personal message that you want to keep close, our app's message bookmarks feature makes it easy to bookmark and retrieve your most important messages.`,
  },
  {
    image: IconMicrophone,
    title: 'Voice-to-Text',
    description:
      'The voice-to-text feature allows you to send messages without ever having to type a word. Simply speak your message, and our app will convert it to text and send it.',
  },
  {
    image: IconTextSize,
    title: 'Document Editor',
    description: `Whether you're working on a blog post, article, or any other type of written content, the document editor makes it easy to create professional content with the help of AI tools.`,
  },
  {
    image: IconMoon,
    title: 'Light / Dark modes',
    description:
      'It provides you with flexibility to choose the mode that suits your preference and enhances your user experience.',
  },
]

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: rem(80),
    paddingBottom: rem(50),
  },

  itemTitle: {
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: rem(0.5),
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.sm,
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: rem(5),
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
}))

export function FeaturesImages() {
  const { classes } = useStyles()

  const items = data.map((item) => (
    <Stack key={item.title}>
      <ThemeIcon
        variant="gradient"
        gradient={{ from: 'indigo.3', to: 'cyan.3' }}
        size={60}
        radius="md"
      >
        <item.image size="2rem" />
      </ThemeIcon>

      <div>
        <Text fw={700} fz="lg" className={classes.itemTitle}>
          {item.title}
        </Text>
        <Text>{item.description}</Text>
      </div>
    </Stack>
  ))

  return (
    <Container className={classes.wrapper}>
      <Text className={classes.supTitle}>Use Cases</Text>

      <Title className={classes.title} order={2}>
        What can you expect?
      </Title>

      <Container size={700} p={0}>
        <Text color="dimmed" className={classes.description}>
          Whether you&apos;re looking to create professional content, optimize your marketing
          strategy, gain a competitive edge or just need some entertainment, NoLimitAI has you
          covered. Join the AI revolution and discover what&apos;s possible with NoLimitAI today.
        </Text>
      </Container>

      <SimpleGrid
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: 450, cols: 1, spacing: 40 },
          { maxWidth: 650, cols: 2, spacing: 40 },
        ]}
        style={{ marginTop: 30 }}
      >
        {items}
      </SimpleGrid>
    </Container>
  )
}
