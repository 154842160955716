import { Avatar, createStyles, keyframes, rem } from '@mantine/core'
import { IconStar } from '@tabler/icons-react'
import * as React from 'react'

import { OrderByDirectionEnum, useCharacterImagesQuery } from '../../../generated/graphql'
import { getPagination } from '../../utils/helpers-client'

const scrollX = keyframes({
  from: { transform: 'translate3d(0, 0, 0)' },
  to: { transform: 'translate3d(-100%, 0, 0)' },
})

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--gap)',
    maxWidth: '100vw',
  },

  marquee: {
    display: 'flex',
    overflow: 'hidden',
    userSelect: 'none',
    gap: 'var(--gap)',
    maskImage: `linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 15%,
      hsl(0 0% 0% / 1) 85%,
      hsl(0 0% 0% / 0)
      )`,
  },

  marqueeGroup: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 'var(--gap)',
    minWidth: '100%',
    animation: `${scrollX} var(--duration) linear infinite`,
  },

  itemTitle: {
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    letterSpacing: rem(0.5),
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.xl,
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    padding: rem(5),
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: theme.colorScheme === 'dark' ? theme.white : 'inherit',
  },
}))

export function CharactersHighway() {
  const { classes } = useStyles()
  const characterImagesQuery = useCharacterImagesQuery({
    variables: {
      filterData: {
        userIds: [null],
      },
      pagination: getPagination({
        dataLimit: 'characterImages',
        page: '1',
        orderBy: 'createdAt',
        orderByDirection: OrderByDirectionEnum.Desc,
      }),
    },
  })

  const characterImages = characterImagesQuery.data?.characterImages.characterImages ?? []

  const items = Array.from({ length: characterImages.length ? 30 : 0 }).map((_, index) => {
    const img = characterImages[index % characterImages.length].thumbnailSignedUrl
    return (
      <Avatar src={img} color="blue" radius="md" key={index} size="xl">
        <IconStar size="3.5rem" />
      </Avatar>
    )
  })

  return (
    <div className={classes.wrapper}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div className={classes.marquee} key={index}>
          <div
            className={classes.marqueeGroup}
            style={
              index % 2 > 0 ? { animationDirection: 'reverse', animationDelay: '-3s' } : undefined
            }
          >
            {items}
          </div>

          <div
            aria-hidden="true"
            className={classes.marqueeGroup}
            style={
              index % 2 > 0 ? { animationDirection: 'reverse', animationDelay: '-3s' } : undefined
            }
          >
            {items}
          </div>
        </div>
      ))}
    </div>
  )
}
