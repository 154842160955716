import {
  Accordion,
  Button,
  Card,
  Grid,
  Group,
  Highlight,
  Stack,
  Title,
  createStyles,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'
import ReactPlayer from 'react-player'

import { UnAuthTemplate } from '../../components/AppTemplate'
import { AuthCard } from '../../components/AuthCard'
import { SYSTEM_CHARACTER } from '../../constants'
import { NextPageWithLayout } from '../../types/general'
import { Chat } from '../Conversation/Chat'
import { CharactersHighway } from './CharactersHighway'
import { FeaturesImages } from './Features'
import { KeyFeatures } from './KeyFeatures'

const useStyles = createStyles((theme) => ({
  background: {
    width: '100vw',
    minHeight: '100vh',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    padding: '120px 24px 160px',
    pointerEvents: 'none',
    zIndex: -1,
    '&:before': {
      background:
        theme.colorScheme === 'dark'
          ? `radial-gradient(circle,rgba(0,0,0,0) 0,${theme.colors.dark[7]} 100%)`
          : 'radial-gradient(circle,rgba(2,0,36,0) 0,#fafafa 100%)',
      position: 'absolute',
      content: '""',
      zIndex: 2,
      width: '100%',
      height: '100%',
      top: 0,
    },
  },
  backgroundContent: {
    zIndex: 3,
    maxWidth: '640px',
    backgroundImage:
      'radial-gradient(at 27% 37%,#3a8bfd 0,transparent 0),radial-gradient(at 97% 21%,#72fe7d 0,transparent 50%),radial-gradient(at 52% 99%,#fd3a4e 0,transparent 50%),radial-gradient(at 10% 29%,#855afc 0,transparent 50%),radial-gradient(at 97% 96%,#e4c795 0,transparent 50%),radial-gradient(at 33% 50%,#8ca8e8 0,transparent 50%),radial-gradient(at 79% 53%,#eea5ba 0,transparent 50%)',
    position: 'absolute',
    content: '""',
    width: '100%',
    height: '100%',
    filter: 'blur(100px) saturate(150%)',
    top: '80px',
    opacity: 0.15,
  },
}))

const useAccordionStyles = createStyles(() => ({
  control: {
    padding: 0,
  },
  content: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  item: {
    padding: 0,
    'button:hover': {
      background: 'transparent',
    },
  },
}))

export const Home: NextPageWithLayout = () => {
  const { classes, theme } = useStyles()
  const [accordion, setAccordion] = React.useState<string | null>('demo2')
  const isLtSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const accordionStyles = useAccordionStyles()

  return (
    <>
      <div className={classes.background}>
        <div className={classes.backgroundContent}></div>
      </div>
      <Stack align="center" w="100%" p="xl">
        <Stack mt="xl" mb="xl" sx={{ maxWidth: '42rem', textAlign: 'center' }}>
          <Title
            sx={{ fontSize: isLtSm ? '3rem' : '4rem' }}
            weight={900}
            color={theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[8]}
          >
            <Highlight
              align="center"
              lh={1.25}
              highlight={['NoLimitAI']}
              highlightStyles={(theme) => ({
                backgroundImage: theme.fn.linearGradient(
                  45,
                  theme.colors.cyan[5],
                  theme.colors.indigo[5]
                ),
                fontWeight: 700,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              })}
            >
              NoLimitAI
            </Highlight>
          </Title>
          <Title
            order={isLtSm ? 2 : 1}
            weight={600}
            color={theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[8]}
          >
            Your ultimate virtual friend just a click away
          </Title>
          <Highlight
            fz={isLtSm ? 'lg' : 'xl'}
            align="center"
            lh={1.25}
            highlight={['coolest', 'human']}
            highlightStyles={(theme) => ({
              backgroundImage: theme.fn.linearGradient(
                45,
                theme.colors.cyan[5],
                theme.colors.indigo[5]
              ),
              fontWeight: 700,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            })}
          >
            Hello, human, my new friend! Welcome to my virtual home, the coolest place on the
            Internet. Okay, maybe not THE coolest, but definitely up there.
          </Highlight>
          <Group position="center" mt="md">
            <AuthCard>
              <Button radius="sm" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                Sign in
              </Button>
            </AuthCard>
            <AuthCard isDefaultSignup>
              <Button radius="sm" color="blue" variant="subtle">
                Sign up
              </Button>
            </AuthCard>
          </Group>
        </Stack>

        <Stack w="100%" maw="70rem">
          <Card w="100%" h="33rem" shadow="md" padding="sm" radius="md" withBorder={false}>
            <Chat characterId={SYSTEM_CHARACTER.homePage} canSelectSentiment shouldRequestLogin />
          </Card>

          <Card
            mt="lg"
            w="100%"
            mih="30rem"
            p={0}
            radius="md"
            withBorder={true}
            bg="transparent"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Grid grow m={0} h="100%" sx={{ flex: 1 }} columns={isLtSm ? 1 : 3}>
              <Grid.Col span={1} miw="12rem">
                <Accordion
                  onChange={setAccordion}
                  value={accordion}
                  classNames={accordionStyles.classes}
                >
                  <Accordion.Item value="demo2">
                    <Accordion.Control>
                      <Title order={4}>Elevate Your Writing Game</Title>
                    </Accordion.Control>
                    <Accordion.Panel>
                      {`With just a few clicks, you can generate an email and turn it into a professional document that is ready to be sent. But that's not all, NoLimitAi also comes equipped with other AI tools that can help you edit your email to perfection.`}
                      <br />
                      <br />
                      {`Say goodbye to the days of struggling to write a professional email or document. With my AI app, you can create high-quality content in a matter of minutes. Whether you're a student, a professional, or anyone in between, my app is designed to make your life easier.`}
                    </Accordion.Panel>
                  </Accordion.Item>
                  <Accordion.Item value="demo1">
                    <Accordion.Control>
                      <Title order={4}>
                        Community - General Discussions, Feature Requests, and Bug Reports
                      </Title>
                    </Accordion.Control>
                    <Accordion.Panel>
                      Users can join the community, create new threads, and participate in
                      discussions
                      <br />
                      <br />
                      One of the primary benefits of the Community Feature is that it allows users
                      to submit feature requests and bug reports. This means that users can have a
                      direct impact on the future of the platform by suggesting new features or
                      reporting issues that need to be addressed. This will help to improve the
                      overall user experience and ensure that the platform continues to meet the
                      needs of its users.
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value="demo3">
                    <Accordion.Control>
                      <Title order={4}>Explore NoLimitAI Dark Mode</Title>
                    </Accordion.Control>
                    <Accordion.Panel>
                      Sleek and modern design of our app in a dark color scheme that is easy on the
                      eyes. With a chat feature that seamlessly integrates with the rest of the app,
                      you can communicate with ease while still keeping the focus on your work.
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Grid.Col>

              <Grid.Col span={isLtSm ? 1 : 2} pb={0} pr={isLtSm ? undefined : 0} miw="18rem">
                <Stack justify="flex-end" align="center" h="100%">
                  {accordion && (
                    <Card
                      mt="auto"
                      maw={isLtSm ? '400px' : undefined}
                      radius="lg"
                      shadow="xl"
                      p={0}
                      withBorder={false}
                      sx={
                        isLtSm
                          ? {
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                            }
                          : {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: 0,
                            }
                      }
                      bg="white"
                    >
                      <ReactPlayer
                        playsinline
                        loop
                        muted
                        playing
                        style={{ display: 'flex' }}
                        url={`/${accordion}.mp4`}
                        width={'100%'}
                        height={'100%'}
                      />
                    </Card>
                  )}
                </Stack>
              </Grid.Col>
            </Grid>
          </Card>

          <FeaturesImages />
        </Stack>
      </Stack>
      <CharactersHighway />
      <KeyFeatures />
    </>
  )
}

Home.getLayout = (page) => <UnAuthTemplate>{page}</UnAuthTemplate>
